import { refDebounced } from '@vueuse/core'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export const searchQueryKey = 'search'

export const useSearch = () => {
  const route = useRoute()
  const router = useRouter()

  const searchQuery = computed({
    get: () => (route.query[searchQueryKey] as string | null) ?? null,
    set(value: string | null) {
      router.replace({
        // replace empty string with undefined to remove empty query
        query: { ...route.query, [searchQueryKey]: value || undefined },
      })
    },
  })

  const searchQueryDebounced = refDebounced(searchQuery, 400)

  return { searchQuery, searchQueryDebounced }
}
